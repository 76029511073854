@import "variables";

#header_nav{
	padding-left: 5px;
	padding-right: 15px;
}
#header_nav li{
	border-left:#ddd solid 1px;
	list-style-type: none;
	padding: 15px 20px;
	display: inline-block;
	float: left;
	font-size: 14px;
	text-transform: uppercase;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}
#header_nav li em{
	font-style: normal;
}

#header_nav li a{
	color: #777;
	line-height:0.8;
	position: relative;
}

#header_nav li#your_account i{
	font-size: 25px;
	color: #777;
	margin-right: 8px;
	float: left;
	transform: translate(0,3px);
	-webkit-transform: translate(0,3px);
}

#header_nav li#shopping_cart{
	vertical-align: middle;
	padding: 19px 20px 20px 20px;
}
#header_nav li#shopping_cart *{
	display: inline-block;
	vertical-align: middle;
}

#header_nav li#shopping_cart a{
	color: theme-color(secondary);
}
#header_nav li#shopping_cart i{
	color: theme-color(secondary);	
	font-size: 20px;
	margin-right:4px;
}
#header_nav li span{
	display: block;
	font-size: 11px;
	font-family: filson-pro, sans-serif;
    font-weight: 300;
	text-transform: none;
}
#header_nav li .logout span{
	color: theme-color(secondary);
	font-family: filson-pro, sans-serif;
    font-weight: 700;
}

#header_nav li span.ajax_cart_quantity{
	background: theme-color(primary);
	color: #333;
	font-size: 14px;
	font-family: filson-pro, sans-serif;
    font-weight: 700;
	display: inline-block !important;
	padding: 4px 6px;
	border-radius:100%;
	line-height: 0.8;
	position: absolute;
	top: -8px;
	right: -13px;
}

@media(max-width:991px){
	#header_nav{
		padding-left: 0px;
	}
	#header_nav li{
		height: 49px;
		padding:8px 15px;
	}
	#header_nav li#shopping_cart{
		padding: 11px 15px;
	}
	#header_nav li em,
	#header_nav li span{
		display: none !important;
	}
	.logout,
	.login{
		display: none;
	}
	#header_nav li i{
		margin-right: 0 !important;
	}
}

@media(max-width:340px){
	#header_nav li{
		padding-left:10px;
		padding-right:10px;
	}
	#header_nav{
		padding-right: 10px;
	}
}